<template>
  <div class="modalUnavailable" data-testid="modal-unavailable">
    <div class="modalUnavailable__poster">
      <div class="modalUnavailable__poster--header">
        <div class="buttonClose">
          <span @click="closeAction"><IconSax name="close-circle" /></span>
        </div>
      </div>
      <div class="modalUnavailable__poster--body">
        <div class="modalContent">

          <div class="modalContent__icon">
            <IconSax name="lock" />
          </div>
          
          <template>
            <div class="modalContent__title">
              {{ message.title }}
            </div>
            <div class="modalContent__description">
               {{ message.description }}
            </div>
          </template>
        </div>
        <div class="modalContent__button">
          <ButtonPlay size="smm" type="normal" @click="closeAction">
            Entendi
          </ButtonPlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import moment from 'moment';

import 'moment/locale/pt-br'; 

export default {
  computed: {
    ...mapGetters({
      getModalInfoCourse: "home/getModalInfoCourse"
    })
  },
  data() {
    return {
      message: {
        title: "",
        description: ""
      }
    }
  },

  mounted () {
    this.showMessage();
  },
  methods: {
    closeAction() {
      this.$emit("close");
    },
    formatDateForLanguage(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    showMessage() {
      const expr = this.getModalInfoCourse.data.type;

      switch (expr) {
        case 1:
          this.message = {
            title: "Aula indisponível",
            description: `Fique atento, a aula estará disponível em: ${this.formatDateForLanguage(this.getModalInfoCourse.data.date)}`
          }
          break;
        case 2: 
          this.message = {
            title: "Aula indisponível",
            description: `Continue avançando em seu progresso para desbloquear essa aula.`
          }
          break;
        case 3: 
          this.message = {
            title: "Aula indisponível",
            description: `Continue avançando em seu progresso para desbloquear essa aula. No entanto, a aula estará disponível em: ${this.formatDateForLanguage(this.getModalInfoCourse.data.date)}`
          }
          break;
        case 4:
          this.message = {
            title: "Módulo indisponível",
            description: `Fique atento, o módulo estará disponível em: ${this.formatDateForLanguage(this.getModalInfoCourse.data.date)}`
          }
          break;
        case 5: 
          this.message = {
            title: "Módulo indisponível",
            description: `Continue avançando em seu progresso para desbloquear esse módulo.`
          }
          break;
        case 6: 
          this.message = {
            title: "Módulo indisponível",
            description: `Continue avançando em seu progresso para desbloquear esse módulo. No entanto, o módulo estará disponível em: ${this.formatDateForLanguage(this.getModalInfoCourse.data.date)}`
          }
          break;
        default:
          this.message = {
            title: "Aula indisponível",
            description: `Fique atento, a aula estará disponível em: ${this.getModalInfoCourse.data.date}`
          }
      }
    }
  },
  
};
</script>

<style lang="scss" scoped>
.modalUnavailable {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  background: #00000070;
  &__poster {
    border-radius: calc(var(--default-radius) * 2);
    box-shadow: var(--shadow-c);
    background: var(--modal-background);
    width: 424px;
    height: 240px;
    flex-shrink: 0;
    padding: var(--spacing-20);
    &--header {
      display: flex;
      justify-content: flex-end;
      > .buttonClose {
        cursor: pointer;
        transition: opacity 0.3s ease;
        &:hover {
          opacity: 0.7;
        }
        ::v-deep {
          svg {
            width: 20px;
            height: 20px;
            path {
              fill: var(--modal-close-color);
            }
          }
        }
      }
    }
    &--body {
      @include flex-column;
      padding: 0px var(--spacing-20);
      height: 165px;
      justify-content: space-between;
        
      .modalContent {
        @include flex-column;
        gap: var(--spacing-8);
        width: 300px;
        &__icon {
          ::v-deep svg path {
            fill: var(--fontcolor2);
          }
        }
        &__title {
          color: var(--fontcolor2);
          @extend .body-bold-16;
        }
        &__description {
          
          color: var(--fontcolor2);
          @extend .body-regular-14;
        }
      }
    }
  }
}
</style>
